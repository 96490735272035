import React, { useState, useEffect } from 'react'; 

import { Form, Button, Container, Row, Col, Modal, Image } from 'react-bootstrap'; 

import './App.css'; 

// starting values 

const Calculator = () => { 

  const [totalUserPopulation, setTotalUserPopulation] = useState(2500); 

  const [averageQuestions, setAverageQuestions] = useState(1500); 

  const [retrievalRag, setRetrievalRag] = useState('RAG'); // 'Retrieval', 'RAG', or 'Digital Assistant' 

  const [useCaseType, setUseCaseType] = useState('Chunky'); 

  const [peakConcurrency, setPeakConcurrency] = useState(null); 

  const [recommendedPackage, setRecommendedPackage] = useState(''); 

  // State for modal and selected image 

  const [showModal, setShowModal] = useState(false); 

  const [selectedImage, setSelectedImage] = useState(''); 

  // Dynamic sliders 

  useEffect(() => { 

    updateSliderBackground('totalUserPopulationSlider', logToLinear(totalUserPopulation, 1, 1000000)); 

    updateSliderBackground('averageQuestionsSlider', logToLinear(averageQuestions, 1, 1000000)); 

  }, [totalUserPopulation, averageQuestions]); 

  // Function to handle image click 

  const handleImageClick = (imageSrc) => { 

    setSelectedImage(imageSrc); 

    setShowModal(true); 

  }; 

  // Function to close modal 

  const handleCloseModal = () => setShowModal(false); 

  // All the math - string to numbers 

  const handleCalculation = () => { 

    const userPopulation = parseFloat(totalUserPopulation); 

    const questionsPerUser = parseFloat(averageQuestions); 

    let useCaseFactor; 

    switch (useCaseType) { 

      case 'Perfectly flat': 

        useCaseFactor = 1; 

        break; 

      case 'Moderately flat': 

        useCaseFactor = 0.7; 

        break; 

      case 'Chunky': 

        useCaseFactor = 0.4; 

        break; 

      case 'Moderately spiky': 

        useCaseFactor = 0.1; 

        break; 

      case 'Extremely spiky': 

        useCaseFactor = 0.01; 

        break; 

      default: 

        useCaseFactor = 0; 

    } 

    const retrievalFactor = retrievalRag === 'RAG' || retrievalRag === 'Digital Assistant' ? 3 : 1; 

    const peakConcurrency = Math.round((userPopulation * (questionsPerUser / 1)) / (7200000 / retrievalFactor) / useCaseFactor); 

    let recommendedPackage; 

    if (peakConcurrency <= 3) { 

      recommendedPackage = 'XS'; 

    } else if (peakConcurrency <= 6) { 

      recommendedPackage = 'S'; 

    } else if (peakConcurrency <= 11) { 

      recommendedPackage = 'M'; 

    } else if (peakConcurrency <= 15) { 

      recommendedPackage = 'L'; 

    } else if (peakConcurrency <= 20) { 

      recommendedPackage = 'XL'; 

    } else { 

      recommendedPackage = 'Contact Dell@Pryon.com for customer configuration'; 

    } 

    setPeakConcurrency(peakConcurrency); 

    setRecommendedPackage(recommendedPackage); 

  }; 

  const linearToLog = (value, min, max) => { 

    const minp = 0; 

    const maxp = 100; 

    const minv = Math.log(min); 

    const maxv = Math.log(max); 

    const scale = (maxv - minv) / (maxp - minp); 

    return Math.round(Math.exp(minv + scale * (value - minp))); 

  }; 

  const logToLinear = (value, min, max) => { 

    const minp = 0; 

    const maxp = 100; 

    const minv = Math.log(min); 

    const maxv = Math.log(max); 

    const scale = (maxv - minv) / (maxp - minp); 

    return Math.round((Math.log(value) - minv) / scale + minp); 

  }; 

  const handleTotalUserPopulationChange = (e) => { 

    const value = linearToLog(parseFloat(e.target.value), 1, 1000000); 

    setTotalUserPopulation(value); 

  }; 

  const handleAverageQuestionsChange = (e) => { 

    const value = linearToLog(parseFloat(e.target.value), 1, 1000000); 

    setAverageQuestions(value); 

  }; 

  const updateSliderBackground = (sliderId, value) => { 

    const slider = document.getElementById(sliderId); 

    const percentage = (value - slider.min) / (slider.max - slider.min) * 100; 

    slider.style.setProperty('--value', `${percentage}%`); 

    slider.setAttribute('data-value', value); 

  }; 

  return ( 

    <Container className="container"> 

      <Row> 

        <Col md={12} className="text-center"> 

          <h1 className="mb-4">Pryon Hardware Sizing Configurator</h1> 

        </Col> 

      </Row> 

      <Row> 

        <Col md={6} className="text-center"> 

          <p className="description">How to use the Calculator.</p> 

          <img 

            src={`${process.env.PUBLIC_URL}/slide3.png`} 

            alt="How to use" 

            className="img-fluid mb-4" 

            onClick={() => handleImageClick(`${process.env.PUBLIC_URL}/slide3.png`)} 

            style={{ cursor: 'pointer' }} 

          /> 

          <img 

            src={`${process.env.PUBLIC_URL}/slide4.png`} 

            alt="Additional info" 

            className="img-fluid mb-4" 

            onClick={() => handleImageClick(`${process.env.PUBLIC_URL}/slide4.png`)} 

            style={{ cursor: 'pointer' }} 

          /> 

          <img 

            src={`${process.env.PUBLIC_URL}/slide5.png`} 

            alt="Additional info" 

            className="img-fluid mb-4" 

            onClick={() => handleImageClick(`${process.env.PUBLIC_URL}/slide5.png`)} 

            style={{ cursor: 'pointer' }} 

          /> 

        </Col> 

        <Col md={6} className="styled-box"> 

          <Form> 

            <Form.Group controlId="totalUserPopulation"> 

              <Form.Label className="form-label">Total User Population:</Form.Label> 

              <div className="custom-slider-container"> 

                <Form.Control 

                  type="number" 

                  value={totalUserPopulation} 

                  onChange={(e) => { 

                    setTotalUserPopulation(Math.round(e.target.value)); 

                    updateSliderBackground('totalUserPopulationSlider', logToLinear(e.target.value, 1, 1000000)); 

                  }} 

                  className="form-control me-2" 

                  min={1} 

                  max={1000000} 

                /> 

                <input 

                  type="range" 

                  id="totalUserPopulationSlider" 

                  min={0} 

                  max={100} 

                  value={logToLinear(totalUserPopulation, 1, 1000000)} 

                  onChange={(e) => { 

                    handleTotalUserPopulationChange(e); 

                    updateSliderBackground('totalUserPopulationSlider', e.target.value); 

                  }} 

                  className="custom-slider" 

                  data-value={totalUserPopulation} 

                /> 

              </div> 

            </Form.Group> 

            <Form.Group controlId="averageQuestions"> 

              <Form.Label className="form-label">Average Questions per User per Year:</Form.Label> 

              <div className="custom-slider-container"> 

                <Form.Control 

                  type="number" 

                  value={averageQuestions} 

                  onChange={(e) => { 

                    setAverageQuestions(Math.round(e.target.value)); 

                    updateSliderBackground('averageQuestionsSlider', logToLinear(e.target.value, 1, 1000000)); 

                  }} 

                  className="form-control me-2" 

                  min={1} 

                  max={1000000} 

                /> 

                <input 

                  type="range" 

                  id="averageQuestionsSlider" 

                  min={0} 

                  max={100} 

                  value={logToLinear(averageQuestions, 1, 1000000)} 

                  onChange={(e) => { 

                    handleAverageQuestionsChange(e); 

                    updateSliderBackground('averageQuestionsSlider', e.target.value); 

                  }} 

                  className="custom-slider" 

                  data-value={averageQuestions} 

                /> 

              </div> 

            </Form.Group> 

            <Form.Group controlId="retrievalRag"> 

              <Form.Label className="form-label">Retrieval or RAG:</Form.Label> 

              <Form.Control 

                as="select" 

                value={retrievalRag} 

                onChange={(e) => setRetrievalRag(e.target.value)} 

                className="form-control" 

              > 

                <option value="Retrieval">Retrieval</option> 

                <option value="RAG">RAG</option> 

                <option value="Digital Assistant">Digital Assistant</option> 

              </Form.Control> 

            </Form.Group> 

            <Form.Group controlId="useCaseType"> 

              <Form.Label className="form-label">Use Case Type:</Form.Label> 

              <Form.Control 

                as="select" 

                value={useCaseType} 

                onChange={(e) => setUseCaseType(e.target.value)} 

                className="form-control" 

              > 

                <option value="Perfectly flat">Perfectly flat</option> 

                <option value="Moderately flat">Moderately flat</option> 

                <option value="Chunky">Chunky</option> 

                <option value="Moderately spiky">Moderately spiky</option> 

                <option value="Extremely spiky">Extremely spiky</option> 

              </Form.Control> 

            </Form.Group> 

            <Button variant="primary" onClick={handleCalculation} className="btn-primary mt-3">Calculate</Button> 

          </Form> 

          {peakConcurrency !== null && ( 

            <div className="mt-4"> 

              <p className="result-text">Peak Concurrency: {peakConcurrency} concurrent questions</p> 

              <p className="result-text">Recommended Package: {recommendedPackage}</p> 

            </div> 

          )} 

          <div className="mt-4 text-center"> 

            <a href="mailto:Dell@Pryon.com" className="feedback-link"> 

              Have feedback on the configurator? Send us an email 

            </a> 

          </div> 

        </Col> 

      </Row> 

      {/* Modal for expanding images */} 

      <Modal show={showModal} onHide={handleCloseModal} centered size="lg"> 

        <Modal.Body> 

          <Image src={selectedImage} fluid /> 

        </Modal.Body> 

      </Modal> 

    </Container> 

  ); 

}; 

export default Calculator;