// src/InfoPage.js
import React from 'react';
//basic webpage with two stacked images
const InfoPage = () => {
  return (
    <div>
      <h1>Information Page</h1>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <img src="slide1.png" alt="Slide 1" style={{ width: '80%', marginBottom: '20px' }} />
        <img src="slide2.png" alt="Slide 2" style={{ width: '80%' }} />
      </div>
    </div>
  );
};

export default InfoPage;
