// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Calculator from './Calculator';
import InfoPage from './InfoPage';
import './App.css';
//switchin web pages or tabs
function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <nav className="navbar">
            <div className="nav-logo">
              <img src="/pryonlogo.png" alt="Pryon Logo" className="logo" />
            </div>
            <div className="nav-center">
              <ul className="nav-list">
                <li className="nav-item">
                  <Link to="/info">Information</Link>
                </li>
                <li className="nav-item">
                  <Link to="/">Calculator</Link>
                </li>
              </ul>
            </div>
          </nav>
        </header>
        <main className="main-container">
          <div className="content-box">
            <Routes>
              <Route path="/" element={<Calculator />} />
              <Route path="/info" element={<InfoPage />} />
            </Routes>
          </div>
        </main>
      </div>
    </Router>
  );
}

export default App;